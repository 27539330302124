import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Lottie from 'react-lottie';
import { Helmet } from 'react-helmet';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { Banner, Modal } from './styles';
import LiveChat from '~/components/LiveChat';
import Player from '~/components/Player';
import thumbModal from '~/assets/defaults/thumb-live-video.png';
import chatIcon from '~/assets/icons/chat-icon.svg';
import Input from '~/components/Input';
import { useResize } from '~/hooks/Resize';
import arrow from '~/assets/icons/arrow.svg';
import muteImg from '~/assets/icons/mute.svg';
import postease from '~/assets/defaults/postease-997.png';
import postease3x from '~/assets/defaults/postease-997-3x.png';
import waves from '~/assets/animations/waves-sound.json';
import swalError from '~/utils/swalError';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import Payment from '~/components/Payment';

interface IParams {
  reference?: string;
  origin?: string;
}

interface IVideo {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setShowBtn: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentVideo: React.Dispatch<React.SetStateAction<number>>;
  currentVideo: number;
}

const VideoChat: React.FC<IVideo> = ({
  currentVideo,
  setShow,
  setShowBtn,
  setCurrentVideo,
}) => {
  const { width } = useResize();
  const [registered, setRegistered] = useState(false);
  const [mute, setMute] = useState(true);

  const handleEnded = useCallback(() => {
    const vid = document.getElementById('video') as HTMLVideoElement;
    if (vid) {
      vid.currentTime = vid.duration;
      vid.pause();
    }

    // setPageThanks(true);
  }, []);

  const handleCurrentVideo = useCallback(() => {
    const vid = document.getElementById('video') as HTMLVideoElement;
    if (vid) {
      setCurrentVideo(Math.floor(vid.currentTime));
    }
    if (currentVideo === 90 && registered === false) {
      vid.pause();
      setShow(true);
      setRegistered(true);
    }

    if (currentVideo > 1399) {
      setShowBtn(true);
    }

    sessionStorage.setItem(
      '@AutoAffiliate:timeUpdate',
      currentVideo.toString()
    );
  }, [currentVideo, registered, setCurrentVideo, setShow, setShowBtn]);

  const handleMute = useCallback(() => {
    setMute((state) => !state);
  }, []);

  return (
    <>
      <div className="position-relative">
        <button
          type="button"
          onClick={handleMute}
          className={`btn-mute w-100 h-100 border-none position-absolute ${
            !mute && 'opacity-0'
          }`}
        >
          <span className="mute pt-3 pt-md-5">
            <Lottie
              options={{
                animationData: waves,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={width > 480 ? 100 : 50}
            />
            <span className="d-block mt-3 mt-md-5 mb-3">
              Your Video Is Playing Click Here To Unmute
            </span>{' '}
            <img src={muteImg} alt="mute" />
          </span>
        </button>
        <Player
          id="video"
          buttonId="play"
          autoPlay
          muted={mute}
          src="https://cdn.autoaffiliate.ai/videos/PostEase+Auto+Webinar-new.mp4"
          thumbnail={thumbModal}
          onEnded={handleEnded}
          onTimeUpdate={handleCurrentVideo}
        />
      </div>
    </>
  );
};

const LiveVideo: React.FC = () => {
  const location = useLocation();
  const { width } = useResize();
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IParams>();
  const [activeChat, setActiveChat] = useState(true);
  const [show, setShow] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(0);
  const [register, setRegister] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [, setReference] = useState('');
  const [userData, setUserData] = useState(() => {
    const data = sessionStorage.getItem('@AutoAffiliate:userDataLiveVideo');
    if (data) {
      return JSON.parse(data);
    }
    return undefined;
  });

  const tag = useMemo(() => {
    const data = new URLSearchParams(location.search);
    return `${data.get('tag')}, `;
  }, [location.search]);

  // codigo abaixo para esconder intercom
  const intercomInterval = setInterval(() => {
    const intercom = document.getElementById('live-chat-ai-host');
    if (intercom && intercom.style.display !== 'none') {
      intercom.style.display = 'none';
    } else {
      clearInterval(intercomInterval);
    }
  }, 10);

  setTimeout(() => {
    clearInterval(intercomInterval);
  }, 30000);

  const handleActiveChat = useCallback(() => {
    setActiveChat(!activeChat);
  }, [activeChat]);

  useEffect(() => {
    if (params.reference) {
      localStorage.setItem('@AutoAffiliate:reference', params.reference);
      setReference(params.reference);
    } else {
      const referenceData = localStorage.getItem('@AutoAffiliate:reference');
      if (referenceData) {
        setReference(referenceData);
      }
    }
  }, [params.reference]);

  useEffect(() => {
    if (width < 992) {
      setActiveChat(true);
    }
  }, [width]);

  useEffect(() => {
    const vid = document.getElementById('video') as HTMLVideoElement;
    const currentTimeVideo = sessionStorage.getItem(
      '@AutoAffiliate:timeUpdate'
    );
    if (typeof currentTimeVideo === 'string') {
      const time = parseInt(currentTimeVideo, 10);
      if (vid) {
        vid.currentTime = time;
      }
    }
  }, []);

  const handleClose = useCallback(() => {
    setShowPayment(false);
  }, []);

  const handleClickPlay = useCallback(() => {
    const play = document.getElementById('play') as HTMLButtonElement;
    if (play) {
      play.click();
    }
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          name: yup.string().required('Full name is required'),
          email: yup.string().required('Email is required'),
        });

        await schema.validate(data, { abortEarly: false });

        let originData = '';
        if (params.origin) {
          switch (params.origin) {
            case 'rf':
              originData = 'AI Bot Facebook, ';
              break;
            case 'uf':
              originData = 'Facebook, ';
              break;
            case 'ue':
              originData = 'User Email, ';
              break;
            case 'ux':
              originData = 'User Twitter (X), ';
              break;
            case 'uw':
              originData = 'User Whatsapp, ';
              break;
            case 'utt':
              originData = 'User Tiktok, ';
              break;
            case 'ul':
              originData = 'User Linkedin, ';
              break;
            case 'ut':
              originData = 'User Traffic, ';
              break;
            case 'aat':
              originData = 'AutoAffiliate Traffic, ';
              break;
            default:
              originData = '';
              break;
          }
        }

        const responseIp = await axios.get('https://api.ipify.org?format=json');
        const formData = {
          name: data.name,
          email: data.email,
          reference: params.reference,
          ip: responseIp.data.ip,
        };
        setShow(false);
        handleClickPlay();
        setRegister(true);
        const response = await api.post('users/webinar', formData, {
          params: {
            origin: `${originData}${tag}Auto Live Video`,
          },
        });
        setUserData(response.data);
        sessionStorage.setItem(
          '@AutoAffiliate:userDataLiveVideo',
          JSON.stringify(response.data)
        );
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          swalError({
            message:
              'Looks like what you were trying to do didn’t work, please try again.',
            textButton: 'Try Again',
          });
        }
      }
    },
    [handleClickPlay, params.origin, params.reference, tag]
  );

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          name="description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://autoaffiliate.ai" />
        <meta
          property="og:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="og:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://autoaffiliate.ai" />
        <meta
          property="twitter:title"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:description"
          content="AutoAffiliate - Discover How to Make Money Online Faster Than EVER before!"
        />
        <meta
          property="twitter:image"
          content="https://api.autoaffiliate.ai/files/metatag-image.png"
        />

        <title>
          AutoAffiliate - Discover How to Make Money Online Faster Than EVER
          before!
        </title>
      </Helmet>

      <Banner>
        <div className="container position-relative vh-lg-100 container-zindex">
          <div className={`${width < 992 && 'd-none'} row`}>
            <div className="col-12 d-flex justify-content-end position-absolute mt-3 px-3">
              <button
                type="button"
                className={`${activeChat && 'd-none'} btn-chat`}
                onClick={handleActiveChat}
                title="Coming Soon"
              >
                <img src={chatIcon} alt="Chat" />{' '}
              </button>
            </div>
          </div>

          <div
            className={`row h-lg-100 ${
              !activeChat && 'justify-content-center'
            }`}
          >
            <div
              className={`col-lg-8 col-xxl-9 px-sm-4 ${
                activeChat ? 'px-xl-5' : 'px-xl-0'
              } left-side`}
            >
              <div className="row justify-content-center">
                <div className="col-12">
                  <h1
                    id={register ? 'live-video-registered' : ''}
                    className="text-center mb-lg-0 mb-xxl-2 pt-3 pt-xxl-4"
                  >
                    YOUR SOCIAL ACCOUNTS{' '}
                    <span>SHOULD BE EARNING YOU MONEY</span> - LET OUR AI
                    SYSTEMS DO THE WORK.
                  </h1>

                  <p className="text-center mb-2 my-xxl-2">
                    And How You Can Start Leveraging It Today To{' '}
                    <span>Build Multiple Streams Of Income</span>
                  </p>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className={`${activeChat ? '' : 'col-xl-10'} col-xxl-11`}>
                  <VideoChat
                    currentVideo={currentVideo}
                    setShow={setShow}
                    setShowBtn={setShowBtn}
                    setCurrentVideo={setCurrentVideo}
                  />
                </div>
              </div>
              {showBtn && (
                <div className="row justify-content-center">
                  <div
                    className={`${activeChat ? '' : 'col-xl-10'} col-xxl-11`}
                  >
                    <button
                      type="button"
                      onClick={() => setShowPayment(true)}
                      className="w-100 btn-yes mt-3 mb-lg-2"
                    >
                      YES! I WANT AI WORKING FOR ME NOW!
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div
              className={`col-lg-4 col-xxl-3 px-0 ps-lg-3 pe-lg-0 ${
                activeChat ? 'd-block' : 'd-none'
              }`}
            >
              <div className="h-lg-100 bg-right-side">
                <LiveChat
                  chatId="live-video"
                  active={activeChat}
                  onClick={handleActiveChat}
                  initWebinar={false}
                  videoTime={currentVideo}
                  userData={userData}
                />
              </div>
            </div>
          </div>
        </div>
      </Banner>

      <Modal show={show} centered className="modal-verify-email">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Modal.Header className="border-none pb-3 pt-4" />
          <Modal.Body className="body">
            <div className="progress progress-striped">
              <div className="progress-bar position-relative">
                <span>80% COMPLETED</span>
                <div className="progress-animation position-absolute" />
              </div>
            </div>
            <h2>WANT TO CONTINUE WATCHING THE VIDEO?</h2>
            <h3>Enter Your Details Below</h3>
            <Input
              name="name"
              type="text"
              placeholder="Full Name"
              className="bd-input"
            />
            <Input
              name="email"
              type="email"
              placeholder="Email"
              className="bd-input"
            />
            <button
              type="submit"
              className="d-flex align-items-center justify-content-center mt-4 btn btn-submit"
            >
              CONTINUE <img src={arrow} alt="Arrow" className="ms-3" />
            </button>
          </Modal.Body>
          <Modal.Footer className="border-none" />
        </Form>
      </Modal>
      <Payment
        show={showPayment}
        onHide={handleClose}
        productName="PostEASE Automation - Webinar Special"
        btnText="Yes, Process My Order!"
        onSuccessfullyPaid={handleClose}
        options={[
          {
            selected: true,
            price: 997,
            src: postease,
            productSlug: 'postease-automation-webinar-special',
            type: 'subscription',
            interval: 'year',
            period: 1,
          },
          {
            price: 397,
            src: postease3x,
            productSlug: 'postease-automation-webinar-special',
            type: 'subscription',
            interval: 'month',
            period: 1,
          },
        ]}
      />
    </>
  );
};

export default LiveVideo;
